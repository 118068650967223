import React, { useEffect, useMemo, useState } from 'react'

import { SuspenseLoading } from '../../Components'
import { useGetUserQuery } from '../../Lib/graphql'
import { ICurrentUserContext } from './interfaces'

export const CurrentUserContext = React.createContext<ICurrentUserContext>({
  firstName: '',
  lastName: '',
  fullName: '',
})

export const CurrentUserProvider: React.FC<{ children: React.ReactNode }> = (props): JSX.Element => {
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')

  const { data, loading } = useGetUserQuery()

  const { children } = props

  useEffect(() => {
    const user = data?.users[0]
    if (!user) return
    setFirstName(user.first_name || '')
    setLastName(user.last_name || '')
  }, [data])

  const fullName = [firstName, lastName].filter((part) => part !== '').join(' ')

  const options = useMemo(() => {
    return {
      firstName,
      lastName,
      fullName,
    }
  }, [firstName, fullName, lastName])

  if (loading) return <SuspenseLoading />
  return <CurrentUserContext.Provider value={options}>{children}</CurrentUserContext.Provider>
}
