// @ts-ignore
import './index.module.scss'
// eslint-disable-next-line import/no-unresolved
import 'virtual:svg-icons-register'

import { Auth0Provider } from '@auth0/auth0-react'
import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'

// @ts-ignore
ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <Auth0Provider
    domain="login.islamstudies.nl"
    clientId="6aMk6jO3K7XuPYAdBVf6W5e2rITe6nN2"
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
    cacheLocation="localstorage"
  >
    <App />
  </Auth0Provider>
  // </React.StrictMode>
)
